// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-medications-js": () => import("/vercel/path0/src/templates/medications.js" /* webpackChunkName: "component---src-templates-medications-js" */),
  "component---src-templates-posts-js": () => import("/vercel/path0/src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-pages-404-js": () => import("/vercel/path0/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/vercel/path0/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-us-js": () => import("/vercel/path0/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("/vercel/path0/src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("/vercel/path0/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-and-events-js": () => import("/vercel/path0/src/pages/news-and-events.js" /* webpackChunkName: "component---src-pages-news-and-events-js" */),
  "component---src-pages-online-refills-index-js": () => import("/vercel/path0/src/pages/online-refills/index.js" /* webpackChunkName: "component---src-pages-online-refills-index-js" */),
  "component---src-pages-online-refills-refill-form-js": () => import("/vercel/path0/src/pages/online-refills/refill-form.js" /* webpackChunkName: "component---src-pages-online-refills-refill-form-js" */),
  "component---src-pages-order-now-js": () => import("/vercel/path0/src/pages/order-now.js" /* webpackChunkName: "component---src-pages-order-now-js" */),
  "component---src-pages-register-for-access-js": () => import("/vercel/path0/src/pages/register-for-access.js" /* webpackChunkName: "component---src-pages-register-for-access-js" */),
  "component---src-pages-thank-you-refill-js": () => import("/vercel/path0/src/pages/thank-you-refill.js" /* webpackChunkName: "component---src-pages-thank-you-refill-js" */),
  "component---src-pages-thank-you-js": () => import("/vercel/path0/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/vercel/path0/.cache/data.json")

